@import "abstracts/_abstracts";
.HeaderBox {
	$block: &;
	position: relative;

	&-content {
		color: currentColor;
		display: flex;
		align-items: center;
		gap: 0.5rem;
		cursor: pointer;
		transition: var(--animationBase);
		position: relative;
		white-space: nowrap;
		font-size: 1rem;
		line-height: 1.5;

		svg {
			width: 1.5rem;
			height: 1.5rem;
		}
	}

	&-dropdown {
		position: absolute;
		z-index: var(--layerDropdownZIndex);
		right: 0;
		top: calc(100% - 1px);
		transform-origin: top right;
		transition: var(--animationBase);
		transform: scale(0);
		opacity: 0;

		&.is-opened {
			transform: scale(1);
			opacity: 1;
		}
	}

	&-label {
		#{$block}--search & {
			border: 0;
			clip: rect(0 0 0 0);
			height: 1px;
			margin: -1px;
			overflow: hidden;
			padding: 0;
			position: absolute;
			width: 1px;
		}
	}

	&-badge {
		position: absolute;
		bottom: 0;
		right: 0;
		transform: translate(50%, 50%);
		background: var(--colorThemeAccent);
		color: var(--colorText);
		font-weight: 700;
		font-size: .5em;
		width: 1.6em;
		height: 1.6em;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		transition: var(--animationBase);

		&:empty {
			opacity: 0;
		}
	}
}